.header {
  height: 80px;
  background: #fff; }
  .header .container {
    overflow: hidden; }
  .header__logo {
    float: left; }
    .header__logo img {
      float: left;
      margin-top: 12px;
      max-height: 57px; }
    .header__logo h1 {
      float: left;
      font-size: 20px;
      font-weight: bold;
      margin: 23px 0 0 13px;
      padding: 0;
      text-transform: uppercase; }
      .header__logo h1 a {
        color: #333;
        text-decoration: none; }
        .header__logo h1 a:hover {
          text-decoration: underline; }
  .header__nav {
    float: right;
    margin-right: -15px; }
    .header__nav a {
      float: left;
      height: 80px;
      padding-top: 28px;
      color: #000;
      text-decoration: none;
      font-size: 14px;
      font-weight: bold;
      margin: 0 15px; }
    .header__nav a.active {
      color: #7F1818; }
  .header__nav-handle {
    display: none; }

.layout-small .header {
  height: auto; }
  .layout-small .header__logo {
    padding: 0 0 13px 20px;
    width: 100%;
    overflow: hidden;
    float: none; }
    .layout-small .header__logo h1 {
      font-size: 14px; }
    .layout-small .header__logo img {
      max-height: 45px; }
  .layout-small .header__nav {
    height: 0;
    overflow: hidden;
    float: none; }
    .layout-small .header__nav a {
      float: none;
      display: block;
      margin: 0;
      height: auto;
      padding: 10px 20px;
      border-bottom: 1px solid #fff;
      background: #eee; }
  .layout-small .header__nav-handle {
    display: block;
    float: right;
    margin: 15px 20px 0;
    background: #eee;
    padding: 5px 10px;
    font-size: 20px;
    cursor: pointer; }
  .layout-small .header-active .header__nav {
    height: auto;
    overflow: visible; }
  .layout-small .header-active .header__nav-handle {
    color: #fff;
    background: #4B4B4B; }

.layout-medium .header {
  padding: 0 20px; }

.carousel__image {
  height:520px;
  width : 100%;
  background-repeat:no-repeat;
  background-size:100% 100%;
}

.dataset {
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 345px;
  border: 1px solid #BFBFBF;
  border-radius: 10px;
  font-size: 14px;
  background: #F5F5F5;
  text-align: left; }
  .dataset__image-wrapper {
    height: 260px;
    padding: 10px;
    text-align: center;
    white-space: nowrap;
    background: #fff;
    border: 1px solid #E3E3E3;
    border-width: 0 0 1px;
    border-radius: 5px 5px 20px 20px;
    cursor: pointer; }
  .dataset__image-aligner {
    display: inline-block;
    height: 100%;
    vertical-align: middle; }
  .dataset__image {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle; }
  .dataset__title {
    display: inline-block;
    padding: 0 10px 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    color: #333;
    text-decoration: none; }
    .dataset__title:hover {
      text-decoration: underline; }
  .dataset__kategori {
    margin-top: 7px;
    display: inline-block;
    padding: 0 10px 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #333;
    text-decoration: none; }
    .dataset__kategori:hover {
      text-decoration: underline; }
  .dataset__author {
    display: inline-block;
    padding: 0 10px 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #333;
    text-decoration: none; }
    .dataset__author:hover {
      text-decoration: underline; }
  .dataset__actions {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 5px;
    border-radius: 0 0 0 5px;
    background: #717171; }
    .dataset__actions a {
      display: inline-block;
      color: #fff;
      text-decoration: none;
      padding: 7px; }
    .dataset__actions a:hover {
      background: #555; }
  .dataset__metadata__header {
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px; }
  .dataset__metadata__title {
    float: left;
    margin: 0;
    padding: 0; }
  .dataset__metadata__close {
    float: right;
    cursor: pointer; }
  .dataset__map {
    width: 100%;
    height: 100%;
    padding-top: 55px; }
    .dataset__map__header {
      margin-top: -55px;
      width: 100%;
      overflow: hidden;
      padding-bottom: 15px; }
    .dataset__map__title {
      float: left;
      margin: 0;
      padding: 0; }
    .dataset__map__close {
      float: right;
      cursor: pointer; }

.layout-small .dataset {
  width: 100%;
  height: 245px; }
  .layout-small .dataset__image-wrapper {
    height: 160px;
    padding: 10px; }

.layout-medium .dataset {
  margin: 0 auto; }

.metadata-panel__title {
  margin: 0;
  padding: 0 0 10px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  text-transform: uppercase;
  color: #999; }

.metadata-panel__content {
  padding-bottom: 20px; }

.metadata-panel__item {
  width: 100%;
  font-size: 14px;
  overflow: hidden;
  padding: 3px 0;
  border-bottom: 1px solid #eee; }

.metadata-panel__item-title {
  float: left;
  width: 230px;
  font-weight: bold; }

.metadata-panel__item-value {
  float: left; }

.dataset-terbaru {
  padding: 60px 0;
  border-top: 2px solid #fff; }
  .dataset-terbaru__loading {
    width: 1px;
    display: block;
    margin: 0 auto; }
  .dataset-terbaru__header {
    position: absolute;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: rotate(90deg) translate(0, 40px);
            transform: rotate(90deg) translate(0, 40px);
    font-size: 24px;
    font-weight: normal; }
    .dataset-terbaru__header__line {
      float: left;
      width: 40px;
      height: 1px;
      margin: 18px 15px 0px -15px;
      background: #333; }
  .dataset-terbaru__list {
    width: 100%;
    overflow: hidden;
    text-align: center; }
    .dataset-terbaru__list__item {
      display: inline-block;
      margin-left: 40px; }
      .dataset-terbaru__list__item:first-child {
        margin-left: 0; }

.layout-small .dataset-terbaru {
  padding: 20px 10px; }
  .layout-small .dataset-terbaru__header {
    position: relative;
    margin: 0 10px 20px 10px;
    font-size: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid #999;
    -webkit-transform: rotate(0) translate(0, 0);
            transform: rotate(0) translate(0, 0); }
  .layout-small .dataset-terbaru__header__line {
    display: none; }
  .layout-small .dataset-terbaru__list {
    width: 100%;
    overflow: hidden; }
    .layout-small .dataset-terbaru__list__item {
      float: left;
      margin-left: 0;
      width: 50%;
      padding: 0 10px 20px; }
      .layout-small .dataset-terbaru__list__item:first-child {
        margin-left: 0; }

.layout-medium .dataset-terbaru {
  padding: 20px 10px; }
  .layout-medium .dataset-terbaru__header {
    position: relative;
    margin: 0 10px 20px 10px;
    font-size: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid #999;
    -webkit-transform: rotate(0) translate(0, 0);
            transform: rotate(0) translate(0, 0); }
  .layout-medium .dataset-terbaru__header__line {
    display: none; }
  .layout-medium .dataset-terbaru__list {
    width: 100%;
    overflow: hidden; }
    .layout-medium .dataset-terbaru__list__item {
      float: left;
      margin-left: 0;
      width: 50%;
      padding: 0 10px 20px; }
      .layout-medium .dataset-terbaru__list__item:first-child {
        margin-left: 0; }

.kategori {
  padding: 60px 0 24px;
  border-top: 2px solid #fff;
  color: #fff;
  background: #4E77A7; }
  .kategori__loading {
    padding-bottom: 70px;
    width: 1px;
    display: block;
    margin: 0 auto; }
  .kategori__header {
    position: absolute;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: rotate(90deg) translate(0, 40px);
            transform: rotate(90deg) translate(0, 40px);
    font-size: 24px;
    font-weight: normal; }
    .kategori__header__line {
      float: left;
      width: 40px;
      height: 1px;
      margin: 18px 15px 0px -15px;
      background: #fff; }
  .kategori__item-list {
    width: 100%;
    overflow: hidden;
    text-align: center; }
  .kategori__item-wrapper {
    display: inline-block;
    text-align: center;
    padding: 0 36px 36px;
    width: 192px;
    vertical-align: top; }
  .kategori__item {
    text-decoration: none;
    color: #4A4A4A; }
    .kategori__item__logo {
      display: block;
      margin: 0 auto;
      height: 120px;
      width: 120px;
      border: 2px solid #fff;
      border-radius: 60px;
      text-align: center;
      overflow: hidden;
      background: #fff; }
    .kategori__item__image {
      display: inline-block;
      max-width: 100%;
      max-height: 100%; }
    .kategori__item:hover .kategori__item__logo {
      box-shadow: 0 0 5px #000;
      border: 1px solid #fff; }
    .kategori__item__label {
      display: block;
      padding-top: 10px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      color: #fff; }

.layout-small .kategori {
  padding: 20px 10px;
  text-align: center; }
  .layout-small .kategori__header {
    position: relative;
    margin: 0 10px 20px 10px;
    font-size: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid #999;
    -webkit-transform: rotate(0) translate(0, 0);
            transform: rotate(0) translate(0, 0); }
  .layout-small .kategori__header__line {
    display: none; }
  .layout-small .kategori__item-wrapper {
    padding: 5px;
    width: 100px;
    height: 140px;
    overflow: hidden;
    display: inline-block;
    float: none; }
  .layout-small .kategori__item__logo {
    width: 80px;
    height: 80px; }
  .layout-small .kategori__item__label {
    font-size: 12px; }

.layout-medium .kategori {
  padding-top: 20px; }
  .layout-medium .kategori__header {
    position: relative;
    margin: 0 20px 20px 20px;
    font-size: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid #999;
    -webkit-transform: rotate(0) translate(0, 0);
            transform: rotate(0) translate(0, 0); }
  .layout-medium .kategori__header__line {
    display: none; }

.instansi {
  padding: 60px 0;
  border-top: 2px solid #fff; }
  .instansi__loading {
    padding-bottom: 70px;
    width: 1px;
    display: block;
    margin: 0 auto; }
  .instansi__header {
    position: absolute;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: rotate(90deg) translate(0, 40px);
            transform: rotate(90deg) translate(0, 40px);
    font-size: 24px;
    font-weight: normal; }
    .instansi__header__line {
      float: left;
      width: 40px;
      height: 1px;
      margin: 18px 15px 0px -15px;
      background: #333; }
  .instansi__item-wrapper {
    text-align: center;
    padding: 20px 0; }
  .instansi__item {
    text-decoration: none;
    color: #4A4A4A;
    display: block; }
    .instansi__item__logo {
      display: block;
      margin: 0 auto;
      height: 120px;
      width: 120px;
      border: 8px solid #C07676;
      border-radius: 5px;
      background: #fff;
      -webkit-transform: rotate(-10deg);
              transform: rotate(-10deg);
      text-align: center;
      overflow: hidden;
      white-space: nowrap; }
    .instansi__item__image-aligner {
      height: 100%;
      display: inline-block;
      vertical-align: middle; }
    .instansi__item__image {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
      display: inline-block !important; }
    .instansi__item:hover .instansi__item__logo {
      -webkit-transform: rotate(0);
              transform: rotate(0); }
    .instansi__item__label {
      display: block;
      padding-top: 10px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold; }

.layout-small .instansi,
.layout-medium .instansi {
  padding: 20px 0 50px; }
  .layout-small .instansi__header,
  .layout-medium .instansi__header {
    position: relative;
    margin: 0 20px 20px;
    font-size: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid #aaa;
    -webkit-transform: rotate(0) translate(0, 0);
            transform: rotate(0) translate(0, 0); }
  .layout-small .instansi__header__line,
  .layout-medium .instansi__header__line {
    display: none; }
  .layout-small .instansi__item,
  .layout-medium .instansi__item {
    text-decoration: none;
    color: #4A4A4A; }
    .layout-small .instansi__item__logo,
    .layout-medium .instansi__item__logo {
      display: block;
      margin: 0 auto;
      height: 70px;
      width: 70px;
      border: 2px solid #C07676;
      border-radius: 5px;
      background: #fff;
      -webkit-transform: rotate(-10deg);
              transform: rotate(-10deg);
      text-align: center;
      overflow: hidden; }
    .layout-small .instansi__item__image,
    .layout-medium .instansi__item__image {
      display: inline-block;
      max-width: 100%;
      max-height: 100%; }
    .layout-small .instansi__item:hover .layout-small .instansi__item__logo,
    .layout-medium .instansi__item:hover .layout-small .instansi__item__logo, .layout-small .instansi__item:hover
    .layout-medium .instansi__item__logo,
    .layout-medium .instansi__item:hover
    .layout-medium .instansi__item__logo {
      -webkit-transform: rotate(0);
              transform: rotate(0); }
    .layout-small .instansi__item__label,
    .layout-medium .instansi__item__label {
      display: block;
      padding-top: 10px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold; }

.link-carousel {
  padding: 60px 0;
  border-top: 2px solid #fff; }
  .link-carousel__loading {
    padding-bottom: 70px;
    width: 1px;
    display: block;
    margin: 0 auto; }
  .link-carousel__header {
    position: absolute;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: rotate(90deg) translate(0, 40px);
            transform: rotate(90deg) translate(0, 40px);
    font-size: 24px;
    font-weight: normal; }
    .link-carousel__header__line {
      float: left;
      width: 40px;
      height: 1px;
      margin: 18px 15px 0px -15px;
      background: #333; }
  .link-carousel__item-wrapper {
    text-align: center;
    padding: 20px 0; }
  .link-carousel__item {
    text-decoration: none;
    color: #4A4A4A;
    display: block; }
    .link-carousel__item__logo {
      display: block;
      margin: 0 auto;
      height: 120px;
      width: 214px;
      border: 1px solid #C07676;
      border-radius: 5px;
      background: #fff;
      text-align: center;
      overflow: hidden;
      white-space: nowrap; }
    .link-carousel__item__image-aligner {
      height: 100%;
      display: inline-block;
      vertical-align: middle; }
    .link-carousel__item__image {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
      display: inline-block !important; }
    .link-carousel__item__label {
      display: block;
      padding-top: 10px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold; }

.layout-small .link-carousel,
.layout-medium .link-carousel {
  padding: 20px 0 50px; }
  .layout-small .link-carousel__header,
  .layout-medium .link-carousel__header {
    position: relative;
    margin: 0 20px 20px;
    font-size: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid #aaa;
    -webkit-transform: rotate(0) translate(0, 0);
            transform: rotate(0) translate(0, 0); }
  .layout-small .link-carousel__header__line,
  .layout-medium .link-carousel__header__line {
    display: none; }
  .layout-small .link-carousel__item,
  .layout-medium .link-carousel__item {
    text-decoration: none;
    color: #4A4A4A; }
    .layout-small .link-carousel__item__logo,
    .layout-medium .link-carousel__item__logo {
      display: block;
      margin: 0 auto;
      height: 70px;
      width: 140px;
      border: 2px solid #C07676;
      border-radius: 5px;
      background: #fff;
      text-align: center;
      overflow: hidden; }
    .layout-small .link-carousel__item__image,
    .layout-medium .link-carousel__item__image {
      display: inline-block;
      max-width: 100%;
      max-height: 100%; }
    .layout-small .link-carousel__item__label,
    .layout-medium .link-carousel__item__label {
      display: block;
      padding-top: 10px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold; }

.berita {
  padding: 60px 0;
  border-top: 2px solid #fff;
  font-size: 14px; }
  .berita .container {
    position: relative; }
  .berita__loading {
    width: 1px;
    margin: 0 auto; }
  .berita__header {
    position: absolute;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: rotate(90deg) translate(0, 40px);
            transform: rotate(90deg) translate(0, 40px);
    font-size: 24px;
    font-weight: normal; }
  .berita__header__line {
    float: left;
    width: 60px;
    height: 1px;
    margin: 18px 15px 0px -15px;
    background: #333; }
  .berita__items {
    width: 100%;
    overflow: hidden; }
  .berita__items__wrapper {
    margin-left: -40px; }
  .berita__item {
    float: left;
    width: 360px;
    margin-left: 40px; }
    .berita__item__tanggal {
      text-transform: uppercase;
      font-weight: 200;
      margin: 0; }
    .berita__item__title {
      cursor: pointer;
      font-size: 18px;
      font-weight: bold;
      margin: 10px 0 15px; }
      .berita__item__title:hover {
        text-decoration: underline; }
    .berita__item p {
      margin: 0;
      font-size: 16px;
      line-height: 1.7; }

.layout-small .berita,
.layout-medium .berita {
  padding: 20px 10px; }
  .layout-small .berita__header,
  .layout-medium .berita__header {
    position: relative;
    margin: 0 10px 20px 10px;
    font-size: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid #999;
    -webkit-transform: rotate(0) translate(0, 0);
            transform: rotate(0) translate(0, 0); }
  .layout-small .berita__header__line,
  .layout-medium .berita__header__line {
    display: none; }
  .layout-small .berita__items__wrapper,
  .layout-medium .berita__items__wrapper {
    margin: 0;
    padding: 0 10px; }
  .layout-small .berita__item,
  .layout-medium .berita__item {
    float: none;
    width: auto;
    margin: 0 0 30px; }
    .layout-small .berita__item__tanggal,
    .layout-medium .berita__item__tanggal {
      font-size: 14px; }
    .layout-small .berita__item__title,
    .layout-medium .berita__item__title {
      font-size: 18px;
      margin: 0 0 5px; }
    .layout-small .berita__item p,
    .layout-medium .berita__item p {
      font-size: 16px; }

.footer {
  padding: 50px 0;
  background: #000;
  color: #ffffff;
  font-size: 14px; }
  .footer .container {
    overflow: hidden; }
  .footer__tentang {
    float: right;
    width: 560px; }
  .footer__kontak {
    float: right;
    width: 265px;
    margin: 0 35px; }
  .footer__didukung {
    float: right;
    width: 265px; }
  .footer__header {
    font-size: 18px;
    font-weight: normal;
    margin-top: 0; }
  .footer__logo {
    width: 100%;
    overflow: hidden; }
    .footer__logo__item {
      float: left;
      padding: 0 20px 0 0; }
    .footer__logo img {
      max-width: 75px;
      max-height: 75px; }

.layout-small .footer,
.layout-medium .footer {
  padding: 0 0 30px; }
  .layout-small .footer__tentang,
  .layout-medium .footer__tentang {
    float: none;
    width: auto;
    padding: 0 20px; }
  .layout-small .footer__kontak,
  .layout-medium .footer__kontak {
    float: none;
    width: auto;
    padding: 0 20px;
    margin: 0; }
  .layout-small .footer__didukung,
  .layout-medium .footer__didukung {
    float: none;
    width: auto;
    padding: 0 20px; }
  .layout-small .footer__header,
  .layout-medium .footer__header {
    font-size: 20px;
    margin: 30px 0 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #aaa; }
  .layout-small .footer p,
  .layout-medium .footer p {
    margin: 0 0 10px; }

.home {
  position: relative; }
  .home .banner {
    position: relative;
    height: 520px;
    width: 100%;
    overflow: hidden;
    background: #ccc; }
    .home .banner__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5); }
  .home__search {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    .home__search__intro {
      width: 730px;
      margin: 90px auto 0;
      font-size: 24px;
      color: #fff;
      text-align: center;
      text-shadow: 1px 1px 2px #000; }

.search {
  position: relative;
  z-index: 9;
  width: 1200px;
  margin: 60px auto 0;
  padding: 20px;
  height: 100px;
  border-radius: 20px;
  background-color: rgba(210, 210, 210, 0.3); }
  .search__advanced-link {
    float: left;
    width: 60px;
    height: 60px;
    cursor: pointer;
    padding-top: 15px;
    border-radius: 30px;
    text-decoration: none;
    color: #4A4A4A;
    font-size: 24px;
    text-align: center;
    background: #E4E4E4; }
  .search__advanced-link:hover {
    background: #ccc; }
  .search-active {
    height: 765px;
    background-color: rgba(210, 210, 210, 0.7); }
  .search-active .search__advanced-link {
    color: #fff;
    background: #4B4B4B; }
  .search-active .search__advanced-link:hover {
    background: #333; }
  .search__input {
    float: right;
    height: 60px;
    width: 1030px;
    font-size: 22px;
    padding-left: 10px;
    padding-right: 10px; }
  .search__select-wrapper {
    float: right;
    overflow: hidden;
    position: relative;
    z-index: 1001;
    height: 60px;
    width: 0;
    background: #E4E4E4; }
  .search-active .search__input {
    width: 670px; }
  .search-active .search__select-wrapper {
    overflow: visible;
    width: 360px; }
  .search__select-kategori {
    float: left;
    width: 50%; }
  .search__select-walidata {
    float: left;
    width: 50%; }
  .search__submit {
    float: right;
    width: 60px;
    height: 60px;
    padding-top: 15px;
    border-radius: 0 5px 5px 0;
    text-decoration: none;
    color: #4A4A4A;
    font-size: 24px;
    text-align: center;
    background: #E4E4E4;
    border-left: 1px solid #aaa; }
  .search__submit:hover {
    background: #ccc; }
  .search__map-wrapper {
    position: relative;
    margin-top: 75px;
    height: 650px;
    width: 100%;
    overflow: hidden; }
  .search__map {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #333;
    border: 1px solid #fff; }

.layout-small .home .banner {
  height: 380px; }

.layout-small .home__search__intro {
  width: auto;
  margin: 40px 20px;
  font-size: 18px; }

.layout-small .search {
  width: 100%;
  border-radius: 0;
  margin: 0;
  padding: 25px 20px 20px;
  height: auto; }
  .layout-small .search__input-wrapper {
    padding: 0 40px 0 45px;
    display: block; }
  .layout-small .search__input {
    float: none;
    width: 100%;
    font-size: 18px;
    height: 40px; }
  .layout-small .search__advanced-link {
    height: 40px;
    width: 40px;
    margin: 0;
    float: left;
    font-size: 18px;
    padding-top: 10px; }
  .layout-small .search__submit-wrapper {
    float: right; }
  .layout-small .search__submit {
    width: 40px;
    height: 40px;
    font-size: 18px;
    margin: 0;
    padding-top: 10px; }
  .layout-small .search__select-wrapper {
    height: 40px;
    margin-top: 5px;
    background: none;
    font-size: 14px; }
  .layout-small .search-active .search__select-wrapper {
    width: 100%; }
  .layout-small .search__map-wrapper {
    margin: 0; }

.layout-medium .home .banner {
  height: 400px; }

.layout-medium .home__search__intro {
  width: auto;
  margin: 40px 60px;
  font-size: 28px; }

.layout-medium .search {
  width: 100%;
  border-radius: 0;
  margin: 0;
  padding: 25px 20px 20px;
  height: auto; }
  .layout-medium .search__input-wrapper {
    padding: 0 60px 0 65px;
    display: block; }
  .layout-medium .search__input {
    float: none;
    width: 100%;
    font-size: 22px;
    height: 60px; }
  .layout-medium .search__advanced-link {
    height: 60px;
    width: 60px;
    margin: 0;
    float: left;
    font-size: 32px;
    padding-top: 10px; }
  .layout-medium .search__submit-wrapper {
    float: right; }
  .layout-medium .search__submit {
    width: 60px;
    height: 60px;
    font-size: 32px;
    margin: 0;
    padding-top: 10px; }
  .layout-medium .search__select-wrapper {
    height: 60px;
    margin-top: 5px;
    background: none;
    font-size: 18px; }
  .layout-medium .search-active .search__select-wrapper {
    width: 100%; }
  .layout-medium .search__map-wrapper {
    margin: 0; }

.jelajah {
  position: relative;
  background: pink;
}
.leaflet-container {
  width: 100%;
  height: 100%;
}

.pencarian_carousel__image {
  height: 130px;
  width: 100%;
  background-size: cover;
}

.pencarian .container {
  overflow: hidden; }

.pencarian__loading {
  width: 1px;
  margin: 0 auto;
  padding: 10px 0; }

.pencarian__nodata {
  text-align: center; }

.pencarian__banner {
  position: relative;
  width: 100%;
  height: 130px;
  overflow: hidden; }
  .pencarian__banner__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); }
    .pencarian__banner__overlay h2 {
      font-size: 36px;
      font-weight: normal;
      color: #fff;
      margin: 40px 0 0; }

.pencarian__filter {
  float: left;
  width: 260px;
  margin: 50px 0;
  border: 1px solid #E3E3E3;
  background: #fff; }
  .pencarian__filter h3 {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    padding: 15px;
    text-transform: uppercase;
    border-bottom: 1px solid #E3E3E3;
    background: #F5F5F5; }
  .pencarian__filter li {
    cursor: pointer; }
  .pencarian__filter-inactive {
    color: #999; }
  .pencarian__filter-active {
    color: #333;
    font-weight: bold; }

.pencarian__panel {
  padding: 0 15px 10px; }
  .pencarian__panel h4 {
    font-size: 16px;
    font-weight: bold;
    color: #525252;
    margin: 10px 0;
    padding: 15px 0 5px;
    border-bottom: 5px solid #D8D8D8; }
  .pencarian__panel ul {
    margin: 0;
    padding: 0; }
  .pencarian__panel li {
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 5px 0;
    font-size: 14px;
    list-style: none; }

.pencarian__filter-item {
  float: left; }

.pencarian__filter-count {
  float: right;
  padding: 3px 8px;
  border-radius: 14px;
  background: #eee; }

.pencarian__peta {
  margin: 10px 0;
  height: 160px;
  background: #eee;
  overflow: hidden; }

.pencarian__content {
  float: right;
  margin-top: 50px;
  width: 900px; }

.pencarian__input-wrapper {
  width: 100%;
  overflow: hidden;
  padding-bottom: 40px;
  position: relative; }

.pencarian__clear {
  position: absolute;
  top: 0;
  right: 62px;
  padding: 18px 10px 0;
  font-size: 20px;
  height: 60px;
  cursor: pointer; }

.pencarian__input {
  float: right;
  height: 60px;
  width: 800px;
  font-size: 22px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #C6C6C6; }

.pencarian__submit {
  float: right;
  width: 60px;
  height: 60px;
  padding-top: 15px;
  border-radius: 0 5px 5px 0;
  text-decoration: none;
  color: #4A4A4A;
  font-size: 24px;
  text-align: center;
  background: #E4E4E4;
  border-left: 1px solid #aaa; }
  .pencarian__submit:hover {
    background: #ccc; }

.pencarian__dataset__list {
  width: 100%;
  overflow: hidden; }
  .pencarian__dataset__list__item {
    float: left;
    margin: 0 0 40px 40px; }

.pagination {
  padding: 20px 0 100px 40px;
  width: 100%;
  overflow: hidden; }
  .pagination__next, .pagination__prev {
    cursor: pointer;
    padding: 10px 17px;
    border: 1px solid #979797;
    background: #fff;
    margin-right: 10px; }
  .pagination__next {
    float: right; }
  .pagination__prev {
    float: left; }

.layout-small .pencarian__input-wrapper,
.layout-medium .pencarian__input-wrapper {
  position: relative;
  padding: 20px 60px 20px 20px; }

.layout-small .pencarian__input,
.layout-medium .pencarian__input {
  width: 100%;
  font-size: 16px;
  height: 50px; }

.layout-small .pencarian__submit,
.layout-medium .pencarian__submit {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 40px;
  height: 50px;
  padding-top: 14px;
  font-size: 20px; }

.layout-small .pencarian__banner,
.layout-medium .pencarian__banner {
  height: 90px; }
  .layout-small .pencarian__banner h2,
  .layout-medium .pencarian__banner h2 {
    margin-top: 30px;
    font-size: 24px;
    padding: 0 20px; }

.layout-small .pencarian__filter,
.layout-medium .pencarian__filter {
  display: none; }

.layout-small .pencarian__clear,
.layout-medium .pencarian__clear {
  top: 13px; }

.layout-small .pencarian__content,
.layout-medium .pencarian__content {
  width: 100%;
  margin: 0; }

.layout-small .pencarian__dataset__list,
.layout-medium .pencarian__dataset__list {
  padding: 10px; }
  .layout-small .pencarian__dataset__list__item,
  .layout-medium .pencarian__dataset__list__item {
    width: 50%;
    margin: 0;
    padding: 10px; }

.detail-berita {
  position: relative;
  padding: 50px 0;
  font-size: 16px; }
  .detail-berita__title {
    margin: 0;
    padding: 0 0 20px;
    font-size: 20px; }
  .detail-berita__date {
    margin: 0;
    padding: 0 0 10px;
    font-size: 14px;
    color: #666; }
  .detail-berita p {
    background: transparent !important; }

* {
  box-sizing: border-box; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  color: #4A4A4A;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F1F1F1; }

.container {
  width: 1160px;
  margin: 0 auto; }

.layout-small .container {
  width: 100%;
  margin: 0; }

.layout-medium .container {
  width: 100%;
  margin: 0; }

.slick-dots li button:before {
  font-size: 15px;
  color: #C47373; }

.slick-dots li.slick-active button:before {
  color: #C47373; }

.ReactModal__Overlay {
  z-index: 9999; }

