.link-carousel {
  padding: 60px 0;
  border-top: 2px solid #fff;

  &__loading {
    padding-bottom: 70px;
    width: 1px;
    display: block;
    margin: 0 auto;
  }
  &__header {
    position: absolute;
    transform-origin: top left;
    transform: rotate(90deg) translate(0, 40px);
    font-size: 24px;
    font-weight: normal;
    &__line {
      float: left;
      width: 40px;
      height: 1px;
      margin: 18px 15px 0px -15px;
      background: #333;
    }
  }
  &__item-wrapper {
    text-align: center;
    padding: 20px 0;
  }
  &__item {
    text-decoration: none;
    color: #4A4A4A;
    display: block;
    &__logo {
      display: block;
      margin: 0 auto;
      height: 120px;
      width: 214px;
      border: 1px solid #C07676;
      border-radius: 5px;
      background: #fff;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
    }
    &__image-aligner {
      height: 100%;
      display: inline-block;
      vertical-align: middle;
    }
    &__image {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
      display: inline-block !important;

    }
    &:hover &__logo {
    }
    &__label {
      display: block;
      padding-top: 10px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}
.layout-small,
.layout-medium {
  .link-carousel {
    padding: 20px 0 50px;

    &__header {
      position: relative;
      margin: 0 20px 20px;
      font-size: 18px;
      padding-bottom: 10px;
      border-bottom: 1px solid #aaa;
      transform: rotate(0) translate(0,0);
    }
    &__header__line {
      display: none;
    }
    &__item {
      text-decoration: none;
      color: #4A4A4A;
      &__logo {
        display: block;
        margin: 0 auto;
        height: 70px;
        width: 140px;
        border: 2px solid #C07676;
        border-radius: 5px;
        background: #fff;
        text-align: center;
        overflow: hidden;
      }
      &__image {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;

      }
      &__label {
        display: block;
        padding-top: 10px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}
