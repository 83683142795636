.dataset {
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 345px;
  border: 1px solid #BFBFBF;
  border-radius: 10px;
  font-size: 14px;
  background: #F5F5F5;
  text-align: left;

  &__image-wrapper {
    height: 260px;
    padding: 10px;
    text-align: center;
    white-space: nowrap;
    background: #fff;
    border: 1px solid #E3E3E3;
    border-width: 0 0 1px;
    border-radius: 5px 5px 20px 20px;
    cursor: pointer;
  }
  &__image-aligner {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  &__image {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }
  &__title {
    display: inline-block;
    padding: 0 10px 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    color: #333;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__kategori {
    margin-top: 7px;
    display: inline-block;
    padding: 0 10px 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #333;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__author {
    display: inline-block;
    padding: 0 10px 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #333;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 5px;
    border-radius: 0 0 0 5px;
    background: #717171;
    a {
      display: inline-block;
      color: #fff;
      text-decoration: none;
      padding: 7px;
    }
    a:hover {
      background: #555;
    }
  }
  &__metadata {
    &__header {
      width: 100%;
      overflow: hidden;
      border-bottom: 1px solid #ccc;
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
    &__title {
      float: left;
      margin: 0;
      padding: 0;
    }
    &__close {
      float: right;
      cursor: pointer;
    }
  }
  &__map {
    width: 100%;
    height: 100%;
    padding-top: 55px;
    &__header {
      margin-top: -55px;
      width: 100%;
      overflow: hidden;
      padding-bottom: 15px;
    }
    &__title {
      float: left;
      margin: 0;
      padding: 0;
    }
    &__close {
      float: right;
      cursor: pointer;
    }
  }
}
.layout-small {
  .dataset {
    width: 100%;
    height: 245px;
    &__image-wrapper {
      height: 160px;
      padding: 10px;
    }
  }
}
.layout-medium {
  .dataset {
    margin: 0 auto;
  }
}
