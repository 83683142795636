.pencarian {
  & .container {
    overflow: hidden;
  }
  &__loading {
    width: 1px;
    margin: 0 auto;
    padding: 10px 0;
  }
  &__nodata {
    text-align: center;
  }
  &__banner {
    position: relative;
    width: 100%;
    height: 130px;
    overflow: hidden;
    &__overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.6);
      h2 {
        font-size: 36px;
        font-weight: normal;
        color: #fff;
        margin: 40px 0 0;
      }
    }
  }
  &__filter {
    float: left;
    width: 260px;
    margin: 50px 0;
    border: 1px solid #E3E3E3;
    background: #fff;

    h3 {
      font-size: 16px;
      font-weight: normal;
      margin: 0;
      padding: 15px;
      text-transform: uppercase;
      border-bottom: 1px solid #E3E3E3;
      background: #F5F5F5;
    }

    li {
      cursor: pointer;
    }

    &-inactive {
      color: #999;
    }
    &-active {
      color: #333;
      font-weight: bold;
    }
  }
  &__panel {
    padding: 0 15px 10px;
    h4 {
      font-size: 16px;
      font-weight: bold;
      color: #525252;
      margin: 10px 0;
      padding: 15px 0 5px;
      border-bottom: 5px solid #D8D8D8;
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      width: 100%;
      overflow: hidden;
      margin: 0;
      padding: 5px 0;
      font-size: 14px;
      list-style: none;
    }
  }
  &__filter-item {
    float: left;
  }
  &__filter-count {
    float: right;
    padding: 3px 8px;
    border-radius: 14px;
    background: #eee;
  }
  &__peta {
    margin: 10px 0;
    height: 160px;
    background: #eee;
    overflow: hidden;
  }
  &__content {
    float: right;
    margin-top: 50px;
    width: 900px;
  }
  &__input-wrapper {
    width: 100%;
    overflow: hidden;
    padding-bottom: 40px;
    position: relative;
  }
  &__clear {
    position: absolute;
    top: 0;
    right: 62px;
    padding: 18px 10px 0;
    font-size: 20px;
    height: 60px;
    cursor: pointer;
  }
  &__input {
    float: right;
    height: 60px;
    width: 800px;
    font-size: 22px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #C6C6C6;
  }
  &__submit {
    float: right;
    width: 60px;
    height: 60px;
    padding-top: 15px;
    border-radius: 0 5px 5px 0;
    text-decoration: none;
    color: #4A4A4A;
    font-size: 24px;
    text-align: center;
    background: #E4E4E4;
    border-left: 1px solid #aaa;
    &:hover {
      background: #ccc;
    }
  }
  &__dataset__list {
    width: 100%;
    overflow: hidden;
  &__item {
    float: left;
    margin: 0 0 40px 40px;
  }
  }
}
.pagination {
  padding: 20px 0 100px 40px;
  width: 100%;
  overflow: hidden;
  &__next,
  &__prev{
    cursor: pointer;
    padding: 10px 17px;
    border: 1px solid #979797;
    background: #fff;
    margin-right: 10px;
  }
  &__next {
    float: right;
  }
  &__prev {
    float: left;
  }
}

.layout-small,
.layout-medium {
  .pencarian {
    &__input-wrapper {
      position: relative;
      padding: 20px 60px 20px 20px;
    }
    &__input {
      width: 100%;
      font-size: 16px;
      height: 50px;
    }
    &__submit {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 40px;
      height: 50px;
      padding-top: 14px;
      font-size: 20px;
    }
    &__banner {
      height: 90px;
      h2 {
        margin-top: 30px;
        font-size: 24px;
        padding: 0 20px;
      }
    }
    &__filter {
      display: none;
    }
    &__clear {
      top: 13px;
    }
    &__content {
      width: 100%;
      margin: 0;
    }
    &__dataset__list {
      padding: 10px;
      &__item {
        width: 50%;
        margin: 0;
        padding: 10px;
      }
    }
  }
}
